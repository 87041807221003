var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"--autoheight "
    + alias2(__default(require("../helpers/ternary.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),(depth0 != null ? lookupProperty(depth0,"size") : depth0),"default",{"name":"ternary","hash":{},"data":data,"loc":{"start":{"line":1,"column":25},"end":{"line":1,"column":58}}}))
    + " "
    + alias2(__default(require("../helpers/ternary.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"classes") : depth0),(depth0 != null ? lookupProperty(depth0,"classes") : depth0),"",{"name":"ternary","hash":{},"data":data,"loc":{"start":{"line":1,"column":59},"end":{"line":1,"column":91}}}))
    + "\">\n	<img src=\""
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"site") : depth0)) != null ? lookupProperty(stack1,"avatars") : stack1)) != null ? lookupProperty(stack1,"large_url") : stack1), depth0))
    + "\" alt=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"site") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\" height=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"dimensions") : depth0), depth0))
    + "\" width=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"dimensions") : depth0), depth0))
    + "\" />\n</div>\n";
},"useData":true});