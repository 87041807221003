var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "		<span class=\"select-list__label label --green\">ORG</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"select-list__item "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"disabled") : depth0), depth0))
    + "\" data-value[0]=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"account") : depth0)) != null ? lookupProperty(stack1,"type") : stack1), depth0))
    + "\" data-value[1]=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"account") : depth0)) != null ? lookupProperty(stack1,"username") : stack1), depth0))
    + "\" data-search=\""
    + alias2(__default(require("../../helpers/toLowerCase.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"account") : depth0)) != null ? lookupProperty(stack1,"username") : stack1),{"name":"toLowerCase","hash":{},"data":data,"loc":{"start":{"line":1,"column":132},"end":{"line":1,"column":166}}}))
    + " "
    + alias2(__default(require("../../helpers/toLowerCase.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"account") : depth0)) != null ? lookupProperty(stack1,"full_name") : stack1),{"name":"toLowerCase","hash":{},"data":data,"loc":{"start":{"line":1,"column":167},"end":{"line":1,"column":202}}}))
    + "\" data-icon=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"account") : depth0)) != null ? lookupProperty(stack1,"avatar") : stack1)) != null ? lookupProperty(stack1,"large_url") : stack1), depth0))
    + "\" tabindex=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"index") : depth0), depth0))
    + "\">\n	<a href=\"#\" class=\"select-list__link\">\n		"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"avatar") : depth0), depth0)) != null ? stack1 : "")
    + "\n		<span class=\"select-list__title\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"account") : depth0)) != null ? lookupProperty(stack1,"full_name") : stack1), depth0))
    + "</span>\n"
    + ((stack1 = __default(require("../../helpers/ifCond.js")).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"account") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"==","Organization",{"name":"ifCond","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":2},"end":{"line":7,"column":13}}})) != null ? stack1 : "")
    + "		"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"svg") : depth0), depth0)) != null ? stack1 : "")
    + "\n	</a>\n</li>\n";
},"useData":true});