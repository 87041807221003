var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"select-list__item\" data-value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"site") : depth0)) != null ? lookupProperty(stack1,"unique_hash") : stack1), depth0))
    + "\" data-search=\""
    + alias2(__default(require("../../helpers/toLowerCase.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"site") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),{"name":"toLowerCase","hash":{},"data":data,"loc":{"start":{"line":1,"column":79},"end":{"line":1,"column":106}}}))
    + "\" data-icon=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"site") : depth0)) != null ? lookupProperty(stack1,"avatars") : stack1)) != null ? lookupProperty(stack1,"thumb_url") : stack1), depth0))
    + "\" tabindex=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"index") : depth0), depth0))
    + "\">\n	<a href=\"#\" class=\"select-list__link\">\n		"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"avatar") : depth0), depth0)) != null ? stack1 : "")
    + "\n		<span class=\"select-list__title\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"site") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</span>\n		"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"svg") : depth0), depth0)) != null ? stack1 : "")
    + "\n	</a>\n</li>\n";
},"useData":true});